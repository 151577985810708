import arrowLeftWhite from "./arrow-left-white.svg";
import backArrowWithoutFill from "./backArrowWithoutFill.svg";
import backArrowImage from "./backArrowImage.svg";
import loader from "./loader.svg";
import downloadIcon from "./downloadIcon.svg";
export {
  arrowLeftWhite,
  backArrowWithoutFill,
  backArrowImage,
  loader,
  downloadIcon,
};
