// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.server-down {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: transparent;
    color: rgba(44, 106, 177, 1);
    text-align: center;
    padding: 20px;
    max-width: 100%; /* Default for mobile */
  }


  .server-down h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-family: "Cardo", Helvetica;
  }

  .server-down p {
    font-size: 1.2rem;
    max-width: 600px; /* Default for mobile */
    font-family: "Cardo", Helvetica;
  }

  .server-down-image{
    width: 300px;
    height: 150px;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    .server-down-image {
      width: 150px;
      height: 150px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/ServerDown/ServerDown.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,6BAA6B;IAC7B,4BAA4B;IAC5B,kBAAkB;IAClB,aAAa;IACb,eAAe,EAAE,uBAAuB;EAC1C;;;EAGA;IACE,eAAe;IACf,mBAAmB;IACnB,+BAA+B;EACjC;;EAEA;IACE,iBAAiB;IACjB,gBAAgB,EAAE,uBAAuB;IACzC,+BAA+B;EACjC;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE;MACE,YAAY;MACZ,aAAa;IACf;EACF","sourcesContent":[".server-down {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: transparent;\n    color: rgba(44, 106, 177, 1);\n    text-align: center;\n    padding: 20px;\n    max-width: 100%; /* Default for mobile */\n  }\n\n\n  .server-down h1 {\n    font-size: 2rem;\n    margin-bottom: 1rem;\n    font-family: \"Cardo\", Helvetica;\n  }\n\n  .server-down p {\n    font-size: 1.2rem;\n    max-width: 600px; /* Default for mobile */\n    font-family: \"Cardo\", Helvetica;\n  }\n\n  .server-down-image{\n    width: 300px;\n    height: 150px;\n    object-fit: contain;\n  }\n\n  @media (max-width: 768px) {\n    .server-down-image {\n      width: 150px;\n      height: 150px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
