// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .checkout-summary-page {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  padding: 62px 63px;
  position: relative;
  width: 98vw;
  height: fit-content;
}

.checkout-summary-page .checkout {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
} */

/* .checkout {
  display: flex;
  flex-direction: column;
  padding-top: 4vh;
} */`, "",{"version":3,"sources":["webpack://./src/Pages/ComponentsPaymentFailed/PaymentFailed.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;GAkBG;;AAEH;;;;GAIG","sourcesContent":["/* .checkout-summary-page {\n  align-items: center;\n  display: inline-flex;\n  flex-direction: column;\n  gap: 10px;\n  padding: 62px 63px;\n  position: relative;\n  width: 98vw;\n  height: fit-content;\n}\n\n.checkout-summary-page .checkout {\n  align-items: center;\n  display: inline-flex;\n  flex: 0 0 auto;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n} */\n\n/* .checkout {\n  display: flex;\n  flex-direction: column;\n  padding-top: 4vh;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
