// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1250px) {

  .cart-subtitle {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2vw;
    position: relative;
    /* width: 100%; */
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 5vh;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1249px) {
  .cart-subtitle {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    /* flex-wrap: wrap; */
    justify-content: center;
    gap: 5px;
    position: relative;
    width: 100%;
    margin-top: 5vh;
  }
}

@media only screen and (max-width: 430px) {
  .cart-subtitle {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    /* flex: 0 0 auto; */
    flex-wrap: wrap;
    justify-content: left;
    gap: 5px;
    position: relative;
    width: 90%;
    margin-top: 5vh;
    margin-left: 3%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/ComponentsCheckoutPage/Components/CartSubtitle/CartSubtitle.css"],"names":[],"mappings":"AAAA;;EAEE;IACE,uBAAuB;IACvB,oBAAoB;IACpB,cAAc;IACd,eAAe;IACf,uBAAuB;IACvB,QAAQ;IACR,kBAAkB;IAClB,iBAAiB;IACjB,uBAAkB;IAAlB,kBAAkB;IAClB,eAAe;EACjB;AACF;AACA;EACE;IACE,uBAAuB;IACvB,oBAAoB;IACpB,cAAc;IACd,qBAAqB;IACrB,uBAAuB;IACvB,QAAQ;IACR,kBAAkB;IAClB,WAAW;IACX,eAAe;EACjB;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,oBAAoB;IACpB,sBAAsB;IACtB,oBAAoB;IACpB,eAAe;IACf,qBAAqB;IACrB,QAAQ;IACR,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,eAAe;EACjB;AACF","sourcesContent":["@media only screen and (min-width: 1250px) {\n\n  .cart-subtitle {\n    align-items: flex-start;\n    display: inline-flex;\n    flex: 0 0 auto;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 2vw;\n    position: relative;\n    /* width: 100%; */\n    width: fit-content;\n    margin-top: 5vh;\n  }\n}\n@media only screen and (min-width: 431px) and (max-width: 1249px) {\n  .cart-subtitle {\n    align-items: flex-start;\n    display: inline-flex;\n    flex: 0 0 auto;\n    /* flex-wrap: wrap; */\n    justify-content: center;\n    gap: 5px;\n    position: relative;\n    width: 100%;\n    margin-top: 5vh;\n  }\n}\n\n@media only screen and (max-width: 430px) {\n  .cart-subtitle {\n    align-items: flex-start;\n    display: inline-flex;\n    flex-direction: column;\n    /* flex: 0 0 auto; */\n    flex-wrap: wrap;\n    justify-content: left;\n    gap: 5px;\n    position: relative;\n    width: 90%;\n    margin-top: 5vh;\n    margin-left: 3%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
