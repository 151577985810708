// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/img/group-11917.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-successful-page {
  display: flex;
  flex-direction: column;
  padding-top: 4vh;
  background-color: #f1fafe;
  height: 100vh;
  overflow: auto;
}

.payment-successful-illus-container {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 100% 100%;
  /* height: 201.69px; */
  position: relative;
  margin-top: 2vh;
  width: 1004px;
}

@media only screen and (max-width: 600px) {
  .payment-successful-page {
    overflow-x: hidden;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/ComponentsPaymentSuccessful/PaymentSuccessful.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,yDAAkD;EAClD,0BAA0B;EAC1B,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,aAAa;AACf;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".payment-successful-page {\n  display: flex;\n  flex-direction: column;\n  padding-top: 4vh;\n  background-color: #f1fafe;\n  height: 100vh;\n  overflow: auto;\n}\n\n.payment-successful-illus-container {\n  background-image: url(/public/img/group-11917.png);\n  background-size: 100% 100%;\n  /* height: 201.69px; */\n  position: relative;\n  margin-top: 2vh;\n  width: 1004px;\n}\n\n@media only screen and (max-width: 600px) {\n  .payment-successful-page {\n    overflow-x: hidden;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
