import React from "react";
import "./ServerDown.css"; // Create a CSS file for styling

const ServerDown: React.FC = () => {
  return (
    <div className="server-down">
      <img
        src={require("../../Assets/images/nfo-logo.png")}
        alt="Server Down"
        className="server-down-image"
      />
      <h1>Oops! Something Went Wrong</h1>
      <p>
        We're currently experiencing a technical issue. Our team is working on
        it, and we appreciate your patience. Please check back in a while.
      </p>
    </div>
  );
};

export default ServerDown;
