// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-subtitle {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  gap: 2vw;
  position: relative;
  width: 100%;
  margin-top: 5vh;
}

@media only screen and (max-width: 1060px) {
  .order-subtitle {
    width: 96vw;
    margin-left: 2vw;
  }
  
}

/* .order-subtitle {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 86px;
  position: absolute;
  top: 208px;
  width: 1027px;
} */`, "",{"version":3,"sources":["webpack://./src/Pages/ComponentsPaymentSuccessful/Components/OrderSubtitle/OrderSubtitle.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,oBAAoB;EACpB,cAAc;EACd,uBAAuB;EACvB,QAAQ;EACR,kBAAkB;EAClB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;;AAEF;;AAEA;;;;;;;;GAQG","sourcesContent":[".order-subtitle {\n  align-items: flex-start;\n  display: inline-flex;\n  flex: 0 0 auto;\n  justify-content: center;\n  gap: 2vw;\n  position: relative;\n  width: 100%;\n  margin-top: 5vh;\n}\n\n@media only screen and (max-width: 1060px) {\n  .order-subtitle {\n    width: 96vw;\n    margin-left: 2vw;\n  }\n  \n}\n\n/* .order-subtitle {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  left: 86px;\n  position: absolute;\n  top: 208px;\n  width: 1027px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
