import axios, { AxiosResponse } from "axios";
import {
  ApiResponseGetItemDetails,
  ApiResponseGetPaymentLink,
  ApiResponseGetRecommendedItems,
  ApiResponseGetStoreItems,
  DataLinks,
  ItemDetailsData,
  ItemDetailsRecommended,
  PaymentLink,
  getOrderStatusResponse,
  getPastOrderResponse,
} from "../DefinitionsInterfaces";
import api from "../axios/axiosMW";
import { ServerResponseGeneral } from "../api/auth";

const fetchItemsList = async (): Promise<void> => {
  try {
    const storedAccessToken = localStorage.getItem("nfoCheckoutAccessToken");
    const response: AxiosResponse<ApiResponseGetStoreItems> = await axios.post(
      "https://streak-onboarding.el.r.appspot.com/nfo/get-store-items",
      {},
      {
        headers: {
          Authorization: `${storedAccessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response = ", response);
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.status_code === 200 &&
      response.data.data
    ) {
      console.log("ItemsObtains");
    } else {
      throw "Invalid response for student search";
    }
  } catch (error) {
    console.log("API failed with error : ", error);
  }
};

export const fetchItemDetails = async (
  itemId: number
): Promise<ItemDetailsData[]> => {
  let foundItems: ItemDetailsData[] = [];
  try {
    const storedAccessToken = localStorage.getItem("nfoCheckoutAccessToken");
    const response: AxiosResponse<ApiResponseGetItemDetails> = await axios.post(
      "https://streak-onboarding.el.r.appspot.com/nfo/get-details-for-item",
      {
        itemID: itemId,
      },
      {
        headers: {
          Authorization: `${storedAccessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response = ", response);
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.status_code === 200 &&
      response.data.data
    ) {
      foundItems.push(response.data.data);
      // setCheckoutItems(response.data.data.items);
    } else {
      throw "Invalid response for student search";
    }
  } catch (error) {
    console.log("API failed with error : ", error);
  }
  return foundItems;
};

export const fetchRecommendedItems = async (
  itemId: number
): Promise<ItemDetailsRecommended[]> => {
  let foundItems: ItemDetailsRecommended[] = [];
  try {
    const storedAccessToken = localStorage.getItem("nfoCheckoutAccessToken");
    const response: AxiosResponse<ApiResponseGetRecommendedItems> =
      await axios.post(
        "https://streak-onboarding.el.r.appspot.com/nfo/get-recommended-items",
        {
          itemID: itemId,
        },
        {
          headers: {
            Authorization: `${storedAccessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    console.log("Response = ", response);
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.status_code === 200 &&
      response.data.data
    ) {
      foundItems = response.data.data.items;
    } else {
      throw "Invalid response for student search";
    }
  } catch (error) {
    console.log("API failed with error : ", error);
  }
  return foundItems;
};

/*
export function getSessionIdBD(data: {
  orderNote: string;
}): Promise<getSessionIdBDResponse> {
  return axios.post(
    "https://streak-payments.el.r.appspot.com/nfo/stage/get-payment-link-for-order",
    {
      ...data,
    },
    {
      headers: {
        Authorization:
          "ZXlKaGJHY2lPaUpTVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnRiMkpwYkdWT2RXMWlaWElpT2lJNU9EazFPRFkyTXpBeklpd2lkWE5sY2tsRUlqb2lXa0ZETURrd01ERWlMQ0oxYzJWeWRIbHdaU0k2SW1OMWMzUnZiV1Z5SWl3aVpYaHdJam94TnpFeE5ETTBNelV4TENKbGJuWWlPaUp3Y205a2RXTjBhVzl1SW4wLm4tWXZoMXdBWURqd3ZTVUFZR0Y5Q2lOUzBOT1d1UWFIUWdpcHdiZlBwQTRrQWN6T1JiNDdTelZ1M194QmRzZk1zZVlJZ0ZmR1dRVkNwZ0VyN01TcmdnVDc1MFJtcXRuOFNlcjhBSWp1RkhyaFh1REpybHVZWTkxR2tFWjFjNzFVT3dnZUoyMDZxX0xGOEhTOHltMHRZREw3SFMydk9FbFF6eld1ZW5qRTNSYw==",
      },
    }
  );
}
*/

export const getPaymentLinkForOrder = async (data: {
  orderNote: string;
}): Promise<AxiosResponse<ApiResponseGetPaymentLink>> => {
  //@ts-ignore
  let response: AxiosResponse<ApiResponseGetPaymentLink> = {
    status: 400,
    statusText: "Failed",
    data: {
      status: "Failed",
      status_code: 400,
      data: {
        orderID: "",
        links: [
          {
            href: "",
            rel: "",
            method: "",
          },
        ],
      },
      message: "",
    },
  };
  try {
    const storedAccessToken = localStorage.getItem("nfoCheckoutAccessToken");
    response = await axios.post(
      "https://streak-payments.el.r.appspot.com/nfo/get-payment-link-for-order",
      {
        ...data,
      },
      {
        headers: {
          // Authorization: "ZXlKaGJHY2lPaUpTVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnRiMkpwYkdWT2RXMWlaWElpT2lJNU5EY3lPVEk0TlRJNUlpd2lkWE5sY2tsRUlqb2lXa0ZETURrd01EVWlMQ0oxYzJWeWRIbHdaU0k2SW1OMWMzUnZiV1Z5SWl3aVpYaHdJam94TnpFeE1ERTBOalk0TENKbGJuWWlPaUp3Y205a2RXTjBhVzl1SW4wLmpOWW1tRzhMVE9KQkNoZkVMdjctbjRyek5YRXdnM3FzeEthb3Q4RTBwSmJoVkRFb0lzejh4S3RERWtUZXZEZWJDa21SQjlJM28waTYwb1cxX3B1cnFNaVpoSXVNZ0tHTzVrVDNFVzlPT1V3Vkc3cTc4UFNTbzNocjlGRlJpVFZuYmdkQjNWTlItZnE2TGhicGlVR1pLdF9tbFJyY2ZoWXlwTzNyeVUtQmxWUQ==",
          Authorization: `${storedAccessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response = ", response);

    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.status_code === 200 &&
      response.data.data
    ) {
      console.log("Successful Response", response);
    } else {
      throw "Invalid response Get Payment Link";
    }
  } catch (error) {
    console.log("API failed with error : ", error);
  }
  return response;
};

export const getPaymentLinkForDoubtClearing = async (data: {
  uniqueBookingID: string | null;
}): Promise<AxiosResponse<ApiResponseGetPaymentLink>> => {
  //@ts-ignore
  let response: AxiosResponse<ApiResponseGetPaymentLink> = {
    status: 400,
    statusText: "Failed",
    data: {
      status: "Failed",
      status_code: 400,
      data: {
        orderID: "",
        links: [
          {
            href: "",
            rel: "",
            method: "",
          },
        ],
      },
      message: "",
    },
  };
  try {
    const storedAccessToken = localStorage.getItem("nfoCheckoutAccessToken");
    response = await axios.post(
      "https://streak-onboarding.el.r.appspot.com/nfo/book",
      {
        ...data,
      },
      {
        headers: {
          // Authorization: "ZXlKaGJHY2lPaUpTVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnRiMkpwYkdWT2RXMWlaWElpT2lJNU5EY3lPVEk0TlRJNUlpd2lkWE5sY2tsRUlqb2lXa0ZETURrd01EVWlMQ0oxYzJWeWRIbHdaU0k2SW1OMWMzUnZiV1Z5SWl3aVpYaHdJam94TnpFeE1ERTBOalk0TENKbGJuWWlPaUp3Y205a2RXTjBhVzl1SW4wLmpOWW1tRzhMVE9KQkNoZkVMdjctbjRyek5YRXdnM3FzeEthb3Q4RTBwSmJoVkRFb0lzejh4S3RERWtUZXZEZWJDa21SQjlJM28waTYwb1cxX3B1cnFNaVpoSXVNZ0tHTzVrVDNFVzlPT1V3Vkc3cTc4UFNTbzNocjlGRlJpVFZuYmdkQjNWTlItZnE2TGhicGlVR1pLdF9tbFJyY2ZoWXlwTzNyeVUtQmxWUQ==",
          Authorization: `${storedAccessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response = ", response);

    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.status_code === 200 &&
      response.data.data
    ) {
      console.log("Successful Response", response);
    } else {
      throw "Invalid response Get Payment Link";
    }
  } catch (error) {
    console.log("API failed with error : ", error);
  }
  return response;
};
export const getPaymentLinkForNFOSessions = async (data: {
  studentID: string | null;
  eventID: string | null;
}): Promise<AxiosResponse<ApiResponseGetPaymentLink>> => {
  //@ts-ignore
  let response: AxiosResponse<ApiResponseGetPaymentLink> = {
    status: 400,
    statusText: "Failed",
    data: {
      status: "Failed",
      status_code: 400,
      data: {
        orderID: "",
        links: [
          {
            href: "",
            rel: "",
            method: "",
          },
        ],
      },
      message: "",
    },
  };
  try {
    const storedAccessToken = localStorage.getItem("nfoCheckoutAccessToken");
    response = await axios.post(
      "https://streak-payments.el.r.appspot.com/nfo/get-payment-link-pre-olympiad",
      {
        ...data,
      },
      {
        headers: {
          // Authorization: "ZXlKaGJHY2lPaUpTVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnRiMkpwYkdWT2RXMWlaWElpT2lJNU5EY3lPVEk0TlRJNUlpd2lkWE5sY2tsRUlqb2lXa0ZETURrd01EVWlMQ0oxYzJWeWRIbHdaU0k2SW1OMWMzUnZiV1Z5SWl3aVpYaHdJam94TnpFeE1ERTBOalk0TENKbGJuWWlPaUp3Y205a2RXTjBhVzl1SW4wLmpOWW1tRzhMVE9KQkNoZkVMdjctbjRyek5YRXdnM3FzeEthb3Q4RTBwSmJoVkRFb0lzejh4S3RERWtUZXZEZWJDa21SQjlJM28waTYwb1cxX3B1cnFNaVpoSXVNZ0tHTzVrVDNFVzlPT1V3Vkc3cTc4UFNTbzNocjlGRlJpVFZuYmdkQjNWTlItZnE2TGhicGlVR1pLdF9tbFJyY2ZoWXlwTzNyeVUtQmxWUQ==",
          Authorization: `${storedAccessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response = ", response);

    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.status_code === 200 &&
      response.data.data
    ) {
      console.log("Successful Response", response);
    } else {
      throw "Invalid response Get Payment Link";
    }
  } catch (error) {
    console.log("API failed with error : ", error);
  }
  return response;
};
export const getPastOrdersAPI = async (): Promise<
  AxiosResponse<getPastOrderResponse>
> => {
  //@ts-ignore
  let response: AxiosResponse<getPastOrderResponse> = {
    status: 400,
    statusText: "Failed",
    data: {
      data: {
        orderedItems: [],
      },
      status: "",
      status_code: 400,
      message: "",
    },
  };
  try {
    const storedAccessToken = localStorage.getItem("nfoCheckoutAccessToken");
    response = await api.post(
      "get-past-orders",
      {},
      {
        headers: {
          // Authorization: "ZXlKaGJHY2lPaUpTVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnRiMkpwYkdWT2RXMWlaWElpT2lJNU5EY3lPVEk0TlRJNUlpd2lkWE5sY2tsRUlqb2lXa0ZETURrd01EVWlMQ0oxYzJWeWRIbHdaU0k2SW1OMWMzUnZiV1Z5SWl3aVpYaHdJam94TnpFeE1ERTBOalk0TENKbGJuWWlPaUp3Y205a2RXTjBhVzl1SW4wLmpOWW1tRzhMVE9KQkNoZkVMdjctbjRyek5YRXdnM3FzeEthb3Q4RTBwSmJoVkRFb0lzejh4S3RERWtUZXZEZWJDa21SQjlJM28waTYwb1cxX3B1cnFNaVpoSXVNZ0tHTzVrVDNFVzlPT1V3Vkc3cTc4UFNTbzNocjlGRlJpVFZuYmdkQjNWTlItZnE2TGhicGlVR1pLdF9tbFJyY2ZoWXlwTzNyeVUtQmxWUQ==",
          Authorization: `${storedAccessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response = ", response.data.data);

    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.status_code === 200
    ) {
      console.log("Successful Response", response);
    } else {
      throw "Invalid response Get Payment Link";
    }
  } catch (error) {
    console.log("API failed with error : ", error);
  }
  return response;
};

export function getOrderStatus(
  orderID: string
): Promise<getOrderStatusResponse> {
  const storedAccessToken = localStorage.getItem("nfoCheckoutAccessToken");
  return axios.post(
    "https://streak-payments.el.r.appspot.com/nfo/verify-payment-for-order",
    {
      orderID: orderID,
    },
    {
      headers: {
        // Authorization: "ZXlKaGJHY2lPaUpTVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnRiMkpwYkdWT2RXMWlaWElpT2lJNU5EY3lPVEk0TlRJNUlpd2lkWE5sY2tsRUlqb2lXa0ZETURrd01EVWlMQ0oxYzJWeWRIbHdaU0k2SW1OMWMzUnZiV1Z5SWl3aVpYaHdJam94TnpFeE1ERTBOalk0TENKbGJuWWlPaUp3Y205a2RXTjBhVzl1SW4wLmpOWW1tRzhMVE9KQkNoZkVMdjctbjRyek5YRXdnM3FzeEthb3Q4RTBwSmJoVkRFb0lzejh4S3RERWtUZXZEZWJDa21SQjlJM28waTYwb1cxX3B1cnFNaVpoSXVNZ0tHTzVrVDNFVzlPT1V3Vkc3cTc4UFNTbzNocjlGRlJpVFZuYmdkQjNWTlItZnE2TGhicGlVR1pLdF9tbFJyY2ZoWXlwTzNyeVUtQmxWUQ==",
        Authorization: `${storedAccessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
}

export interface InvoiceResponse {
  OrderNo: string;
  InvoiceNo: string;
  DateOfInvoice: string;
  Products: [
    {
      HSNCode: string;
      Description: string;
      Quantity: number;
      RatePerUnit: number;
      Amount: number;
    }
  ];
  Subtotal: number;
  TotalTax: number;
  InvoiceTotal: number;
  amountInWords: string;
  invoiceTo: string;
  stateOfUser: string;
}

interface ApiGetInvoiceResponse extends ServerResponseGeneral {
  data: InvoiceResponse;
}

export function getInvoiceApi(
  invoiceNo: string
): Promise<ApiGetInvoiceResponse> {
  const storedAccessToken = localStorage.getItem("nfoCheckoutAccessToken");
  return api.post(
    "get-invoice-data",
    { invoiceNo: invoiceNo },
    {
      headers: {
        // Authorization: "ZXlKaGJHY2lPaUpTVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnRiMkpwYkdWT2RXMWlaWElpT2lJNU5EY3lPVEk0TlRJNUlpd2lkWE5sY2tsRUlqb2lXa0ZETURrd01EVWlMQ0oxYzJWeWRIbHdaU0k2SW1OMWMzUnZiV1Z5SWl3aVpYaHdJam94TnpFeE1ERTBOalk0TENKbGJuWWlPaUp3Y205a2RXTjBhVzl1SW4wLmpOWW1tRzhMVE9KQkNoZkVMdjctbjRyek5YRXdnM3FzeEthb3Q4RTBwSmJoVkRFb0lzejh4S3RERWtUZXZEZWJDa21SQjlJM28waTYwb1cxX3B1cnFNaVpoSXVNZ0tHTzVrVDNFVzlPT1V3Vkc3cTc4UFNTbzNocjlGRlJpVFZuYmdkQjNWTlItZnE2TGhicGlVR1pLdF9tbFJyY2ZoWXlwTzNyeVUtQmxWUQ==",
        Authorization: `${storedAccessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
}
