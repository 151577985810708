import React, { createContext, useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import CheckoutPage from "./Pages/ComponentsCheckoutPage/CheckoutPage";
import PaymentSuccessful from "./Pages/ComponentsPaymentSuccessful/PaymentSuccessful";
import PaymentFailed from "./Pages/ComponentsPaymentFailed/PaymentFailed";
import PdfPreview from "./Pages/PdfPreview/PdfPreview";
import "./overrides";
import InvoicePreview from "./Pages/ComponentsCheckoutPage/Components/InvoicePreview/InvoicePreview";
import { getDetails } from "./Utils/api/auth";
import { CircularProgress } from "@mui/material";
import { setUserId, setUserProperties } from "firebase/analytics";
import { fAnalytics } from "./Utils/firebase/firebaseConfig";
import ServerDown from "./Pages/ServerDown/ServerDown";

export const AuthContext = createContext({} as any);

export const CartContext = createContext({} as any);

//@ts-ignore
const AuthProvider = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [auth, setAuth] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [undiqueId, setUniqueId] = useState<string | undefined>(undefined);
  const [nfoSessionseventId, setNfoSessionseventId] = useState<
    string | undefined
  >(undefined);

  const setParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("token");
    const itemId = urlParams.get("itemId");
    const doubtClearingStartTime = urlParams.get("bookingId");
    const eventId = urlParams.get("eventId");
    console.log({ doubtClearingStartTime, itemId, eventId });

    if (accessToken) {
      // localStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("accessToken", accessToken);
    }
    if (itemId) {
      // localStorage.setItem("itemId", itemId);
      sessionStorage.setItem("itemId", itemId);
      // setStateItemId(itemId);
    }
    if (doubtClearingStartTime) {
      console.log("this is being set", doubtClearingStartTime);
      sessionStorage.setItem("uniqueId", doubtClearingStartTime);
      setUniqueId(doubtClearingStartTime);
    } else {
      setUniqueId(undefined);
      sessionStorage.removeItem("uniqueId");
    }
    if (eventId) {
      console.log(eventId);
      sessionStorage.setItem("nfoSessionseventId", eventId);
      setNfoSessionseventId(eventId);
    } else {
      setNfoSessionseventId(undefined);
      sessionStorage.removeItem("nfoSessionseventId");
    }
    // const localToken = localStorage.getItem("accessToken");
    // return { localToken, itemId };
  };

  const getDetailsStudent = async () => {
    setLoading(true);
    const accessToken = sessionStorage.getItem("accessToken");
    const userName = urlParams.get("userName");
    console.log(accessToken, userName);

    if (accessToken && userName) {
      const isSessionValid = await getDetails(userName, accessToken);
      if (isSessionValid) {
        //@ts-ignore
        if (isSessionValid.data.status == "Success") {
          setUserId(fAnalytics, userName);
          setUserProperties(fAnalytics, {
            userId: userName,
            //@ts-ignore
            grade: isSessionValid.data.data.grade,
            //@ts-ignore
            school: isSessionValid.data.data.school,
          });

          setLoading(false);
          //@ts-ignore
          setAuth(isSessionValid.data.data);
        } else {
          setLoading(true);
        }
      }
    }
  };

  useEffect(() => {
    setParams();
    getDetailsStudent();
  }, []);
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <AuthContext.Provider
          value={{
            auth,
            setAuth,
            loading,
            setLoading,
            undiqueId,
            nfoSessionseventId,
          }}
        >
          {children}
        </AuthContext.Provider>
      )}
    </div>
  );
};

const AppRoutes = () => {
  const { auth } = useContext(AuthContext);
  console.log(auth);
  return auth ? (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="*" element={<Navigate to={"/CheckoutPage"} replace />} />
        <Route path="/CheckoutPage" element={<CheckoutPage />} />
        <Route path="/PaymentSuccessful" element={<PaymentSuccessful />} />
        <Route path="/PaymentFailed" element={<PaymentFailed />} />
        <Route path="/pdf-preview" element={<PdfPreview />} />
        <Route path="/invoice-preview" element={<InvoicePreview />} />
      </Routes>
    </BrowserRouter>
  ) : (
    <div>Invalid session</div>
  );
};

const App = () => {
  return (
    // <AuthProvider>
    //   <AppRoutes />
    // </AuthProvider>
    <ServerDown />
  );
};

export default App;
